.nav-dropdown-container {
  position: absolute;
  top: 80px;
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100vw;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-evenly;
  align-items: top;
  font-size: 1rem;
  position: sticky;
  z-index: 999;
  color: #363636;
}
.item-categories {
  display: flex;
  flex-direction: column;
  text-align: start;
  text-decoration: none;
  color: #363636;
}
.shop-menu-item {
  text-decoration: none;
  color: #363636;
  padding-bottom: 5px;
  transition: color 0.3s ease;
}
.shop-menu-item:hover {
  color: #6f00cc;
}
.item-categories-title {
  font-size: 22px;
}
.navdropdown-pictures {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nav-dropdown-item {
  position: relative;
  display: flex;
  width: 600px;
  height: 200px;
  margin-right: 10px;
  cursor: pointer;
  flex-direction: column;
}
.nav-dropdown-image {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.nav-dropdown-discount-text {
  position: absolute;
  bottom: 10%;
  border: none;
  background-color: rgb(48, 25, 52);
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  font-size: 18px;
  transition: all 0.3s ease;
}
.nav-dropdown-discount-text:hover {
  transform: scale(1.1); /* Increase size on hover */
}
@media screen and (max-width: 1600px) {
  /* Laptop styles */
  .nav-dropdown-item {
    width: 440px;
  }
}
