.services-upper-banner {
  display: flex;
  width: 100%;
  height: 160px;
  overflow: hidden;
}
.services-upper-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services-container {
  margin-bottom: 70px;
}
@media screen and (max-width: 820px) {
  /* Tablet styles */
  .services-upper-banner {
    display: none;
  }
}
@media screen and (max-width: 430px) {
  /* Mobile styles */
  .services-upper-banner {
    display: none;
  }
  .services-container {
    margin-bottom: 30px;
  }
}
