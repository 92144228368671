.consultations-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 30px;
  align-items: center;
  padding-top: 70px;
}
.consultation-hdr {
  color: rgb(48, 25, 52);
}
.consultation-prg {
  font-size: 18px;
}
.consultation-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
  width: 100%;
}
.consultation-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  width: 30%;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add light shadow */
  padding: 40px 40px 40px 40px;
  cursor: pointer;
  transition: all 0.3s ease; /* Add transition property */
}
.consultation-item:hover {
  transform: scale(1.1); /* Increase size on hover */
}
.consultation-btn {
  background-color: rgb(48, 25, 52);
  font-size: 20px;
  color: white;
  width: 200px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  padding-right: 6px;
  border: none;
  border-radius: 22px;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
.consultation-btn:hover {
  background-color: transparent;
  color: rgb(48, 25, 52);
  border: 1px solid rgb(48, 25, 52);
}
.consulation-title {
  color: rgb(48, 25, 52);
  font-size: 26px;
  margin-bottom: 10px;
  text-align: center;
}
.consultation-price {
  font-size: 18px;
  color: gray;
}
.consultation-details {
  font-size: 18px;
  text-align: left;
  margin-bottom: 20px;
}
.check-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.check-prg {
  padding-left: 5px;
}

@media screen and (max-width: 820px) {
  /* Tablet styles */
  .consultations-container {
    padding-top: 50px;
  }
  .consultation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    justify-content: space-between;
    width: 100%;
  }
  .consultation-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    width: 100%;
    margin-bottom: 14px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add light shadow */
    padding: 40px 40px 40px 40px;
    cursor: pointer;
    transition: all 0.3s ease; /* Add transition property */
  }
}
@media screen and (max-width: 375px) {
  .consultations-container {
    padding-top: 50px;
  }
  .consultation-hdr {
    font-size: 22px;
  }
  .consultation-prg {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
  }
  .consulation-title {
    font-size: 22px;
    margin-bottom: 10px;
    text-align: center;
  }
  .consultation-details {
    font-size: 14px;
  }
  .consultation-btn {
    width: 160px;
    font-size: 18px;
  }
}

@media screen and (max-width: 430px) {
  /* Mobile styles */
  .consultations-container {
    padding-top: 50px;
  }
  .consultation-hdr {
    font-size: 22px;
  }
  .consultation-prg {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
  }
  .consulation-title {
    font-size: 22px;
    margin-bottom: 10px;
    text-align: center;
  }
  .consultation-details {
    font-size: 14px;
  }
  .consultation-btn {
    width: 180px;
    font-size: 14px;
  }
}
