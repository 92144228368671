.about-me-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.about-me-image-wrapper {
  display: flex;
  width: 100%;
  height: 900px;
  margin: 50px 30px 0px 30px;
}
.about-me-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.about-me-up {
  display: flex;
  flex-direction: row;
}
.about-me-text {
  display: flex;
  flex-direction: column;
  margin: 50px 30px 0px 10px;
}
.about-me-title {
  text-align: center;
  margin-bottom: 10px;
  color: rgb(48, 25, 52);
  font-size: 40px;
}
.my-story {
  margin-bottom: 20px;
}
.about-me-values {
  margin-bottom: 20px;
}
.about-me-hdr {
  padding: 20px 20px 16px 16px;
  color: rgb(48, 25, 52);
  font-size: 36px;
}
.upper-block {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0 20px;
  justify-content: space-evenly;
}

.upper-block,
.lower-block p {
  font-size: 18px;
}
.info-text {
  font-size: 18px;
  margin-right: 15px;
}
.info-text {
  margin-left: 15px;
}
.about-me-education {
  display: flex;
  flex-direction: column;
  margin: 30px 30px 0px 30px;
}
.education-text {
  display: flex;
  flex-direction: column;
}
.certificates-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
}
.certificate-image-wrapper {
  display: flex;
  width: 100%;
  height: auto;
}
.certificate-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-right: 10px;
}
.block-check {
  display: flex;
  flex-direction: row;
  align-items: top;
}
.block-check-prg {
  padding-left: 10px;
}

@media screen and (max-width: 820px) {
  /* Tablet styles */
  .about-me-up {
    display: flex;
    flex-direction: column;
  }
  .about-me-education {
    margin: 0px 30px 0px 30px;
  }
}

@media screen and (max-width: 430px) {
  /* Mobile styles */
  .about-me-up {
    flex-direction: column;
  }
  .about-me-image-wrapper {
    display: flex;
    width: 94%;
    height: 500px;
    margin: 10px 10px 0px 10px;
  }
  .about-me-text {
    display: flex;
    flex-direction: column;
    margin: 20px 10px 0px 10px;
  }
  .about-me-title {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .about-me-hdr {
    text-align: center;
    font-size: 20px;
    padding: 5px 20px 0 20px;
  }
  .info-text {
    font-size: 16px;
    margin-right: 15px;
  }
  .block-check-prg {
    font-size: 16px;
  }
  .about-me-education {
    margin: 10px 10px 0px 10px;
  }
  .certificates-container {
    flex-direction: column;
  }
  .certificate-image-wrapper {
    margin-bottom: 10px;
  }
}
