.navbar {
  background-color: white;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky !important;
  top: 0;
  z-index: 999;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #c7c7c7;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  height: 80px;
  width: 100%;
  padding: 0 48px;
}

.navbar-logo {
  color: #363636;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 22px;
  display: flex;
  align-items: center;
}

.nav-icons-and-btn-container {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  align-items: center;
}

.navbar-social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  width: 60px;
  margin-right: 30px;
}
.fab {
  font-size: 24px;
  color: rgb(48, 25, 52);
}
.social-icon-link.vk {
  margin: 0;
  padding: 0;
  height: 25px;
}

.social-icon-link.instagram {
  margin: 0;
  padding: 0;
  height: 25px;
}
.nav-menu.active {
  display: none;
}

.nav-menu {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #363636;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: all 0.2s ease-out;
}

.nav-links:hover {
  color: #6f00cc;
}
.nav-links-btn {
  text-decoration: none;
}
.nav-btn {
  background-color: rgb(48, 25, 52);
  font-size: 20px;
  color: white;
  width: 200px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  padding-right: 6px;
  border: none;
  border-radius: 22px;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
  display: block;
}
.nav-btn:hover {
  background-color: transparent;
  color: rgb(48, 25, 52);
  border: 1px solid rgb(48, 25, 52);
}

.menu-icon {
  display: none;
}

.icons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
}

.navigation-cart-icon {
  display: flex;
  position: relative;
  text-decoration: none;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .nav-btn {
    display: none;
  }
  .navbar-social-icons {
    margin-right: 20px;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .menu-icon {
    display: flex;
    align-items: center !important;
    cursor: pointer;
    position: absolute;
    right: 0;
    padding-right: 30px;
  }
  .social-icon-link.vk,
  .social-icon-link.instagram {
    margin: 0;
    padding: 0;
  }
  .nav-menu {
    display: none;
  }
  .nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: hidden;
    position: fixed;
    top: 88px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 100vh;
    padding-top: 20px;
    align-items: flex-start;
    transform: none;
    justify-content: space-between;
  }
  .nav-links {
    text-align: start;
    padding: 0;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-top: 0;
    transition: color 0.3s ease;
    color: black;
    padding-top: 40px;
  }
  .slides-styles {
    width: 700px;
    height: 420px;
    margin: 0 auto;
    margin-left: 0;
  }
  /* Tablet styles */
  .navbar-container {
    align-items: center !important;
  }
  .fa-bars,
  .fa-x {
    color: #363636;
    font-size: 24px;
    align-items: center;
  }
}

@media only screen and (max-width: 375px) {
  .navbar-container {
    padding: 0 20px;
  }
  .navbar-logo {
    font-size: 1.1rem;
  }

  .nav-btn {
    display: none;
  }
  .fab {
    font-size: 24px;
    margin-right: 10px;
  }

  .menu-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    padding-right: 30px;
  }
  .fa-bars,
  .fa-x {
    color: #363636;
    font-size: 1.1rem !important;
  }
  .fab {
    font-size: 1.1rem !important;
    margin-right: 0;
  }
  .navbar-social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    margin-right: 40px;
  }

  .menu-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    padding-right: 20px;
  }
  .fa-bars,
  .fa-x {
    color: #363636;
    font-size: 1.4rem;
  }
  .nav-menu {
    display: none;
  }

  .nav-menu.active {
    background: rgb(48, 25, 52);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: hidden;
    position: fixed;
    top: 88px;
    width: 100%;
    height: auto;
    display: block;
    flex-direction: column;
    padding-bottom: 100vh;
    padding-top: 40px;
    align-items: flex-start;
    transform: none;
  }

  .nav-links {
    text-align: start;
    padding: 0;
    width: 100%;
    position: relative;
    margin-top: 0;
    transition: color 0.3s ease;
    color: white;
  }

  .slides-styles {
    width: 320px;
    height: 180px;
    margin: 0 auto;
    margin-left: 0;
  }
}
@media only screen and (max-width: 390px) {
  .navbar-container {
    padding: 0 20px;
  }
  .navbar-logo {
    font-size: 1.1rem;
  }

  .nav-btn {
    display: none;
  }
  .fab {
    font-size: 24px;
    margin-right: 10px;
  }

  .menu-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    padding-right: 30px;
  }
  .fa-bars,
  .fa-x {
    color: #363636;
    font-size: 1.1rem !important;
  }
  .fab {
    font-size: 1.1rem !important;
    margin-right: 0;
  }
  .navbar-social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    margin-right: 40px;
  }

  .menu-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    padding-right: 20px;
  }
  .fa-bars,
  .fa-x {
    color: #363636;
    font-size: 1.4rem;
  }
  .nav-menu {
    display: none;
  }

  .nav-menu.active {
    background: rgb(48, 25, 52);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: hidden;
    position: fixed;
    top: 88px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 100vh;
    padding-top: 40px;
    align-items: flex-start;
    transform: none;
  }

  .nav-links {
    text-align: start;
    padding: 0;
    width: 100%;
    position: relative;
    margin-top: 0;
    transition: color 0.3s ease;
    color: white;
  }

  .slides-styles {
    width: 340px;
    height: 200px;
    margin: 0 auto;
    margin-left: 0;
  }
}
@media only screen and (max-width: 430px) {
  /* Mobile styles */

  .navbar-container {
    padding: 0 20px;
  }
  .navbar-logo {
    font-size: 1.1rem;
  }
  .nav-btn {
    display: none;
  }
  .fab {
    font-size: 24px;
    margin-right: 10px;
  }

  .menu-icon {
    display: flex; /* Change display property to flex */
    align-items: center; /* Align icon vertically */
    cursor: pointer; /* Add cursor style to indicate clickability */
    position: absolute;
    right: 0;
    padding-right: 30px;
  }
  .fa-bars,
  .fa-x {
    color: #363636;
    font-size: 1.1rem !important;
  }
  .fab {
    font-size: 1.1rem !important;
    margin-right: 0;
  }
  .navbar-social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50px;
    margin-right: 40px;
  }

  .menu-icon {
    display: flex; /* Change display property to flex */
    align-items: center; /* Align icon vertically */
    cursor: pointer; /* Add cursor style to indicate clickability */
    position: absolute;
    right: 0;
    padding-right: 20px;
  }
  .fa-bars,
  .fa-x {
    color: #363636;
    font-size: 1.4rem;
  }
  .nav-menu {
    display: none; /* Hide the menu by default */
  }

  .nav-menu.active {
    /* background: rgb(48, 25, 52); */
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: hidden;
    position: fixed;
    /* top: 88px; */
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 100vh;
    padding-top: 40px;
    align-items: flex-start;
    transform: none;
  }

  .nav-links {
    text-align: start;
    padding: 0;
    width: 100%;
    position: relative;
    margin-top: 0;
    transition: color 0.3s ease;
    color: black;
  }

  .slides-styles {
    width: 320px;
    height: 220px;
    margin: 0 auto;
    margin-left: 0;
  }
}
