.feedback-upper-banner {
  display: flex;
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.feedback-upper-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.feedback-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
}
.feedback-title {
  color: rgb(48, 25, 52);
}
.feedback-hrd {
  color: rgb(48, 25, 52);
  text-align: center;
}
@media only screen and (max-width: 375px) {
  /* Mobile styles */
}
@media only screen and (max-width: 390px) {
  /* Mobile styles */
}
@media only screen and (max-width: 430px) {
  /* Mobile styles */
  .feedback-upper-banner {
    height: 100px;
  }
  .feedback-container {
    margin-top: 30px;
  }
}
