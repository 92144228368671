.ask-question-modal-title {
  color: rgb(48, 25, 52);
  font-size: 19px;
  padding-top: 14px;
}
.ask-question-btn-container {
  display: flex;
  justify-content: end;
}

.ask-question-btn {
  background-color: rgb(48, 25, 52);
  font-size: 18px;
  color: white;
  width: 140px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-right: 4px;
  border: none;
  border-radius: 22px;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
  display: block;
}
.ask-question-btn:hover {
  background-color: transparent;
  color: rgb(48, 25, 52);
  border: 1px solid rgb(48, 25, 52);
}
.ask-question-prg {
  font-size: 14px;
  text-align: center;
  padding: 10px;
}
.alert-message-prg {
  margin-bottom: 0;
  font-size: 16px;
}
.error-message {
  color: rgb(181, 3, 3);
  font-size: 14px;
}
