.help-container {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgb(48, 25, 52);
}
.help-text {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 60px;
}
.help-prg {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.help-check-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.help-checks-hdr {
  font-size: 22px;
}
.help-check {
  margin-bottom: 0;
  font-size: 18px;
  padding-left: 10px;
}
.help-btn {
  background-color: white;
  font-size: 18px;
  color: #363636;
  width: 200px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  padding-right: 6px;
  border: none;
  border-radius: 22px;
  margin-top: 30px;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
.help-btn:hover {
  background-color: rgb(48, 25, 52);
  color: white;
  border: 1px solid white;
}
.help-image-container {
  /* width: 100%; */
  display: flex;
  flex: 40%;
}
.help-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 820px) {
  /* Tablet styles */
  .help-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(48, 25, 52);
  }
}

@media screen and (max-width: 430px) {
  /* Mobile styles */
  .help-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .help-text {
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
  }
  .help-hdr {
    font-size: 22px;
  }
  .help-prg {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .help-checks-hdr {
    font-size: 18px;
  }
  .help-check {
    margin-bottom: 0;
    font-size: 16px;
    padding-left: 10px;
  }
  .help-btn {
    font-size: 14px;
    width: 180px;
  }
}
