.nutritionology-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 30px 30px;
}
.nutritionology-image-container {
  display: flex;
  flex: 30%;
  height: 400px;
}
.nutritionology-image {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.nutritionology-text {
  flex: 70%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.nutritionology-hdr {
  /* color: #28584e; */
  color: rgb(48, 25, 52);
  text-align: center;
}
.nutritionology-prgs {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}
.nutritionology-prg {
  font-size: 18px;
}
.nutritionology-btn {
  background-color: rgb(48, 25, 52);
  font-size: 20px;
  color: white;
  width: 200px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  padding-right: 6px;
  border: none;
  border-radius: 22px;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
.nutritionology-btn:hover {
  background-color: transparent;
  color: rgb(48, 25, 52);
  border: 1px solid rgb(48, 25, 52);
}
@media screen and (max-width: 768px) {
  /* Tablet styles */
  .nutritionology-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .nutritionology-text {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  }
  .nutritionology-hdr {
    color: rgb(48, 25, 52);
    text-align: center;
    margin-top: 36px !important;
  }
  .nutritionology-prgs {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .nutritionology-prg {
    font-size: 18px;
  }
  .nutritionology-btn {
    background-color: rgb(48, 25, 52);
    font-size: 20px;
    color: white;
    width: 200px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
    border: none;
    border-radius: 22px;
    transition: background-color 0.3s, border 0.3s, color 0.3s;
  }
  .nutritionology-btn:hover {
    background-color: transparent;
    color: rgb(48, 25, 52);
    border: 1px solid rgb(48, 25, 52);
  }
}
@media screen and (max-width: 820px) {
  /* Tablet styles */
  .nutritionology-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .nutritionology-image-container {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .nutritionology-image {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .nutritionology-text {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  }
  .nutritionology-hdr {
    color: rgb(48, 25, 52);
    text-align: center;
    margin-top: 36px !important;
  }
  .nutritionology-prgs {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .nutritionology-prg {
    font-size: 18px;
  }
  .nutritionology-btn {
    background-color: rgb(48, 25, 52);
    font-size: 20px;
    color: white;
    width: 200px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
    border: none;
    border-radius: 22px;
    transition: background-color 0.3s, border 0.3s, color 0.3s;
  }
  .nutritionology-btn:hover {
    background-color: transparent;
    color: rgb(48, 25, 52);
    border: 1px solid rgb(48, 25, 52);
  }
}
@media screen and (max-width: 375px) {
  /* Mobile styles */
  .nutritionology-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .nutritionology-image-container {
    display: flex;
    width: 400px;
    height: auto;
    margin-bottom: 10px;
  }
  .nutritionology-image {
    position: relative;
    object-fit: cover;
  }

  .nutritionology-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
  }
  .nutritionology-hdr {
    color: rgb(48, 25, 52);
    text-align: center;
    margin-top: 36px !important;
    font-size: 22px;
  }
  .nutritionology-prgs {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .nutritionology-prg {
    font-size: 16px !important;
    text-align: left;
  }
  .nutritionology-btn {
    font-size: 16px;
    width: 200px;
  }
}
@media screen and (max-width: 390px) {
  /* Mobile styles */
  .nutritionology-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .nutritionology-image-container {
    display: flex;
    width: 50%;
    height: auto;
    margin-bottom: 10px;
  }
  .nutritionology-image {
    position: relative !important;
    object-fit: cover !important;
  }

  .nutritionology-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
  }
  .nutritionology-hdr {
    color: rgb(48, 25, 52);
    text-align: center;
    margin-top: 36px !important;
    font-size: 22px;
  }
  .nutritionology-prgs {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .nutritionology-prg {
    font-size: 16px !important;
  }
  .nutritionology-btn {
    font-size: 16px;
    width: 200px;
  }
}
@media screen and (max-width: 430px) {
  /* Mobile styles */
  .nutritionology-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .nutritionology-image-container {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .nutritionology-image {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .nutritionology-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
    align-items: center;
  }
  .nutritionology-hdr {
    color: rgb(48, 25, 52);
    text-align: center;
    margin-top: 36px !important;
    font-size: 22px;
  }
  .nutritionology-prgs {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .nutritionology-prg {
    font-size: 16px !important;
  }
  .nutritionology-prg-add {
    display: none;
  }
  .nutritionology-btn {
    font-size: 14px;
    width: 180px;
  }
}
