.hero-container {
  background: url("/public/images-food/hero1.jpg") center center/ 100% auto
    no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  margin-bottom: 70px;
  padding-top: 20px;
}

.hero-cnt-title {
  /* color: #28584e; */
  color: rgb(48, 25, 52);
  font-size: 80px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0px;
  text-align: center;
}
.hero-cnt-prg {
  margin-top: 8px;
  margin-bottom: 10px;
  /* color: #28584e; */
  color: rgb(48, 25, 52);
  font-size: 22px;
}
.hero-btn-container {
  margin-top: 20px;
}
.hero-btn {
  /* background-color: #28584e; */
  background-color: rgb(48, 25, 52);
  font-size: 20px;
  color: white;
  width: 260px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  padding-right: 6px;
  border: none;
  border-radius: 22px;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
.hero-btn:hover {
  background-color: transparent;
  color: rgb(48, 25, 52);
  border: 1px solid rgb(48, 25, 52);
}
@media screen and (max-width: 1600px) {
  /* Laptop styles */
  .hero-container {
    background: url("/public/images-food/hero1.jpg") center center/ 140% auto
      no-repeat !important;
  }
  .hero-cnt-title {
    font-size: 76px;
  }
}

@media screen and (max-width: 820px) {
  /* Tablet styles */
  .hero-container {
    background: url("/public/images-food/hero1.jpg") center center/ 140% auto
      no-repeat !important;
    height: 40vh !important;
    margin-bottom: 20px !important;
    padding-top: 36px;
  }
  .hero-cnt-title {
    font-size: 42px !important;
    /* margin-top: 10px; */
  }
  .hero-cnt-prg {
    font-size: 14px !important;
    text-align: center;
  }
  .hero-btn-container {
    margin-top: 0;
  }
}

@media only screen and (max-width: 430px) {
  /* Mobile styles */
  .hero-container {
    background: url("/public/images-food/hero1.jpg") center center/ 200% auto
      no-repeat !important;
    height: 60vh !important;
    margin-bottom: 20px !important;
    padding-top: 20px;
  }
  .hero-cnt-title {
    font-size: 32px !important;
  }
  .hero-cnt-prg {
    display: none;
  }
  .hero-btn-container {
    margin-top: 16px;
  }
  .hero-btn {
    font-size: 14px;
    width: 180px;
  }
}
