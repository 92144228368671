.footer-container {
  margin-top: auto;
  background-color: white;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #c7c7c7;
}

.footer-subscription-and-links {
  display: flex;
  flex-direction: row;
  align-items: top;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  color: #363636;
}

.footer-subscription-heading {
  margin-bottom: 18px;
  font-size: 1.2rem;
}
.footer-subscription-text {
  margin-bottom: 18px;
  font-size: 16px;
}
.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  margin-bottom: 16px;
  border: 1px solid rgb(188, 188, 188);
  border-radius: 6px;
}
.footer-btn {
  /* background-color: #28584e; */
  background-color: rgb(48, 25, 52);
  font-size: 18px;
  color: white;
  width: 200px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  padding-right: 6px;
  border: none;
  border-radius: 22px;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
.footer-btn:hover {
  background-color: transparent;
  color: rgb(48, 25, 52);
  border: 1px solid rgb(48, 25, 52);
}
.footer-links {
  width: 100%;
  display: flex;
  align-items: top;
}
.footer-link-wrapper {
  display: flex;
  flex-direction: row;
}
.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: auto;
  box-sizing: border-box;
  margin-right: 50px;
}
.footer-link-items h2 {
  margin-bottom: 16px;
  font-size: 1.2rem;
  color: #363636;
}
.footer-link-item a {
  color: #363636;
  text-decoration: none;
  margin-bottom: 8px;
}
.footer-link-item a:hover {
  color: #6f00cc;
  transition: 0.3s ease-out;
}
.footer-email-form h2 {
  margin-bottom: 32px;
}
.footer-input::placeholder {
  color: #b1b1b1;
}
.contact-details-email,
.contact-details-phone {
  color: #363636;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
.contact-email,
.contact-phone {
  font-size: 16px;
  padding-left: 10px;
}

.social-footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 10px auto 0 0;
}
.contact-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
}
.footer-logo {
  text-decoration: none !important;
  color: #363636;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
}
.website-rights {
  color: #363636;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
}
.website-year {
  margin-bottom: 0;
}
.website-rights-text {
  font-size: 14px;
  margin-bottom: 0;
}

.text-links {
  text-decoration: none;
  color: #363636;
  transition: color 0.3s ease;
}
.text-links:hover {
  color: #6f00cc;
}
.social-footer-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.social-footer-link {
  padding-left: 30px;
  color: rgb(48, 25, 52);
}

@media screen and (max-width: 768px) {
  /* Tablet styles */

  .footer-subscription-and-links {
    display: flex;
    flex-direction: column;
  }
  .footer-subscription {
    margin-top: 24px;
    margin-bottom: 0;
  }
  .footer-link-wrapper {
    display: flex;
    flex-direction: row !important;
    padding: 20px;
  }
  .footer-links {
    padding-top: 2rem;
  }
  .footer-input {
    width: 100%;
    border: 1px solid gray;
  }
  .social-footer-wrapper {
    flex-direction: column;
  }
  .footer-link-items {
    margin-right: 22px;
  }
  .footer-link-items h2 {
    font-size: 16px !important;
  }
  .footer-left-container {
    display: flex;
    flex-direction: column;
  }
  .footer-logo {
    font-size: 1.5rem;
  }
  .social-contact {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 820px) {
  /* Tablet styles */
  .footer-container {
    padding-top: 0;
    padding-right: 0 !important;
  }
  .footer-subscription-and-links {
    display: flex;
    flex-direction: column;
  }
  .footer-subscription {
    margin-top: 24px;
    margin-bottom: 0;
  }
  .footer-link-wrapper {
    display: flex;
    flex-direction: row !important;
    padding: 20px;
  }
  .footer-links {
    padding-top: 1rem;
  }
  .footer-input {
    width: 100%;
    border: 1px solid gray;
  }
  .social-footer-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    justify-content: space-around;
  }
  .social-footer-link {
    padding-left: 2px;
    color: rgb(48, 25, 52);
  }
  .footer-link-items {
    margin-right: 52px;
  }
  .footer-link-items h2 {
    font-size: 14px !important;
  }
  .footer-left-container {
    display: flex;
    flex-direction: column;
  }
  .text-links {
    font-size: 14px;
  }
  .social-contact {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .contact-email,
  .contact-phone {
    font-size: 14px;
  }
  .website-rights {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 375px) {
  /* Mobile styles */

  .footer-subscription-and-links {
    display: flex;
    flex-direction: column;
  }
  .footer-subscription {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
  }
  .footer-link-wrapper {
    display: flex;
    flex-direction: column !important;
    padding: 20px;
  }
  .footer-links {
    padding-top: 1rem;
  }
  .footer-input {
    width: 100%;
    border: 1px solid gray;
  }
  .social-footer-wrapper {
    flex-direction: column;
  }
  .footer-link-items {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer-link-items h2 {
    font-size: 18px !important;
  }
  .footer-left-container {
    display: flex;
    flex-direction: column;
  }
  .social-contact {
    margin-bottom: 5px;
  }
  .website-rights {
    padding: 20px 20px 0 20px;
    margin-top: 10px;
  }
  .website-rights-text {
    font-size: 12px;
  }
}
@media only screen and (max-width: 390px) {
  /* Mobile styles */
  .footer-subscription-and-links {
    display: flex;
    flex-direction: column;
  }
  .footer-subscription {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
  }
  .footer-link-wrapper {
    display: flex;
    flex-direction: column !important;
    padding: 20px;
  }
  .footer-links {
    padding-top: 1rem;
  }
  .footer-input {
    width: 100%;
    border: 1px solid gray;
  }
  .social-footer-wrapper {
    flex-direction: column;
  }
  .footer-link-items {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer-link-items h2 {
    font-size: 18px !important;
  }
  .footer-left-container {
    display: flex;
    flex-direction: column;
  }
  .footer-logo {
    font-size: 1.5rem;
  }
  .social-contact {
    margin-bottom: 5px;
  }
  .website-rights {
    padding: 20px 20px 0 20px;
    margin-top: 10px;
  }
  .website-rights-text {
    font-size: 12px;
  }
}
@media only screen and (max-width: 430px) {
  /* Mobile styles */
  .footer-container {
    /* padding-top: 0;
    padding-right: 0 !important;
    margin-left: 0 !important; */
    align-items: start;
  }
  .footer-subscription-and-links {
    display: flex;
    flex-direction: column;
  }
  .footer-subscription {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
  }
  .footer-link-wrapper {
    display: flex;
    flex-direction: column !important;
    padding: 5px;
  }
  .footer-links {
    padding-top: 1rem;
  }
  .footer-input {
    width: 100%;
    border: 1px solid gray;
  }
  .social-footer-wrapper {
    flex-direction: column;
  }
  .footer-link-items {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer-link-items h2 {
    font-size: 16px !important;
  }
  .text-links {
    font-size: 14px;
  }
  .footer-left-container {
    display: flex;
    flex-direction: column;
  }
  .social-contact {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .footer-subscription-heading {
    margin-bottom: 8px;
    font-size: 18px;
  }
  .footer-subscription-text {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .footer-input {
    padding: 8px;
    border-radius: 2px;
    margin-right: 20px;
    outline: none;
    border: none;
    font-size: 16px;
    border: 1px solid rgb(188, 188, 188);
    border-radius: 6px;
  }
  .website-rights {
    padding: 5px 20px 0 0;
    margin-top: 10px;
  }
  .website-rights-text {
    font-size: 10px;
  }
  .footer-btn {
    font-size: 16px;
    width: 200px;
  }
}
