.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  /* Tablet styles */
  .home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 820px) {
  /* Tablet styles */
  .home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 430px) {
  /* Mobile styles */
  .home {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
