.request-form-container {
  margin-top: 100px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.request-form-hdr {
  color: rgb(48, 25, 52);
}
.request-form-prg {
  font-size: 18px;
}

.request-form {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
}

.input-container {
  position: relative;
  flex: 1;
  margin-right: 10px;
}

.input-label {
  position: absolute;
  top: -25px;
  left: 0;
  padding: 0 5px;
  background-color: transparent;
  color: rgb(48, 25, 52);
  pointer-events: none;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 6px;
  transition: border 0.3s;
}
.input:focus {
  border: 1px solid rgb(48, 25, 52);
  outline: none;
}
.input-group {
  display: flex;
  margin-bottom: 40px;
}
.message-input {
  border: 1px solid rgb(209, 209, 209);
  border-radius: 6px;
  margin-right: 10px;
  padding-bottom: 80px;
}

.request-form-btn {
  background-color: rgb(48, 25, 52);
  font-size: 20px;
  color: white;
  width: 220px;
  padding: 8px 10px;
  border: none;
  border-radius: 22px;
  margin-top: 30px;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
}
.request-form-btn:hover {
  background-color: transparent;
  color: rgb(48, 25, 52);
  border: 1px solid rgb(48, 25, 52);
}
.success-alert-message {
  font-size: 18px;
  margin-top: 16px;
}
.error-alert-message {
  font-size: 18px;
  margin-top: 16px;
}
@media screen and (max-width: 820px) {
  /* Tablet styles */
  .request-form-container {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .request-form-prg {
    font-size: 18px;
    text-align: center;
  }
}

@media screen and (max-width: 430px) {
  /* Mobile styles */
  .request-form-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .request-form-hdr {
    font-size: 22px;
    text-align: center;
  }
  .request-form-prg {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
  }
  .request-form {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    width: 100%; /* Increase width */
    max-width: 1000px; /* Set maximum width */
  }
  .request-form-btn {
    font-size: 14px;
    width: 180px;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .name-input {
    margin-bottom: 40px;
  }
}
