.what-is-nutri-container {
  display: flex;
  flex-direction: column;
}
.what-is-nutri-title {
  text-align: center;
  color: rgb(48, 25, 52);
  padding-top: 30px;
  padding-bottom: 30px;
}
.what-is-nutri-block {
  display: flex;
  flex-direction: row;
  margin: 10px 30px 50px 30px !important;
}
.nutri-img-container-upper {
  display: flex;
  width: 100%;
  height: 800px;
  padding: 20px;
}
.what-is-nutri-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.nutri-defi-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.nutri-defi-title {
  font-size: 26px;
  color: rgb(48, 25, 52);
}
.definition {
  font-size: 18px;
}
.nutri-img-container {
  display: flex;
  width: 100%;
  height: 800px;
  padding: 20px;
}
.nutri-facts-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.nutri-facts-title {
  font-size: 26px;
  color: rgb(48, 25, 52);
  padding-bottom: 14px;
}
.fact-check {
  display: flex;
  flex-direction: row;
}
.fact {
  padding-left: 10px;
  font-size: 18px;
}
@media screen and (max-width: 820px) {
  /* Tablet styles */
  .what-is-nutri-block {
    display: flex;
    flex-direction: column;
    margin: 0px 10px 10px 10px !important;
  }
  .what-is-nutri-title {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .nutri-img-container {
    display: none;
  }
  .nutri-defi-wrapper {
    padding: 20px;
  }
  .nutri-facts-wrapper {
    padding: 20px;
  }
}

@media screen and (max-width: 430px) {
  /* Mobile styles */
  .nutri-img-container-upper {
    height: 500px;
    padding: 10px;
  }
  .what-is-nutri-title {
    font-size: 26px;
  }
  .nutri-defi-title {
    font-size: 22px;
  }
  .definition {
    font-size: 16px;
  }
  .nutri-facts-title {
    font-size: 22px;
  }
  .fact {
    font-size: 16px;
  }
}
